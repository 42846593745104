
import {
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
  defineComponent,
} from 'vue';
import { useState as useGtag } from 'vue-gtag-next';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import AuthRouterView from '@/modules/auth/views/AuthRouterView.vue';

import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import Footer from '@/components/Footer.vue';
import GenericError from '@/components/GenericError/GenericError.vue';
import Header from '@/components/NavHeader/Header.vue';
import Modal from '@/components/Modal/Modal.vue';

import {
  createOneTrustCookieScript,
  createOneTrustCookieScriptConsent,
  parseCookies,
} from './utils/cookies';

const GIGYA_TTL_IN_SECONDS = 3600; // 1 hour

const createScriptAndAddToHead = (src, document) => {
  const script = document.createElement('script');
  script.setAttribute('src', src);
  document.head.appendChild(script);
};

export default defineComponent({
  components: {
    AuthRouterView,
    Button,
    Footer,
    GenericError,
    Header,
    Modal,
  },
  setup() {
    const checkTechnicalCookiesIntervalId = ref(null);
    const isTechnicalCookieAccepted = ref(false);
    const isAdvCookieAccepted = ref(false);
    const store = useStore();
    const router = useRouter();
    const errors = computed(() => store.state.errors);
    const firstError = computed(() => {
      const data = errors.value[0]?.response?.data;
      return (
        errors.value[0] && {
          ...errors.value[0],
          title: data?.title || errors.value[0].title,
          message: data?.custom_error || data?.error_message,
          // Only on === false we DON'T show. True or Undefined we show.
          showToUser: data?.show_to_user !== false,
          isButtonDisabled: data?.isButtonDisabled,
        }
      );
    });

    const { isEnabled: isGtagEnabled } = useGtag();

    const discardErrors = () => store.commit('EMPTY_ERRORS');
    const go = (url: string) => {
      discardErrors();
      url ? router.push(url) : router.go(-1);
    };

    const handleModalClose = () => {
      discardErrors();
    };

    const logoutAction = () =>
      store.dispatch('authModule/logout', null, { root: true });
    const isLoggedIn = computed(
      () => store.getters['authModule/isUserAuthenticated']
    );

    const checkTechnicalCookies = () => {
      const {
        dw_Advertisement_cookie: advertisementCookieValue,
        dw_Technical_cookie: technicalCookieValue,
      } = parseCookies();

      if (advertisementCookieValue === 'opt-in' && !isAdvCookieAccepted.value) {
        window.dataLayer.push({ event: 'consentoptin' });
        isAdvCookieAccepted.value = true;
      }
      if (advertisementCookieValue === 'opt-out' && isAdvCookieAccepted.value) {
        isAdvCookieAccepted.value = false;
      }

      if (
        technicalCookieValue === 'opt-in' &&
        !isTechnicalCookieAccepted.value
      ) {
        isGtagEnabled.value = true;
        isTechnicalCookieAccepted.value = true;
      }
      if (
        technicalCookieValue === 'opt-out' &&
        isTechnicalCookieAccepted.value
      ) {
        isTechnicalCookieAccepted.value = false;
        isGtagEnabled.value = false;
      }
    };

    const createScripts = () => {
      const gigyaKey = process.env.VUE_APP_GIGYA_KEY;
      const oneTrustCookieID = process.env.VUE_APP_ONETRUST_COOKIE_ID;
      if (gigyaKey) {
        createScriptAndAddToHead(
          `https://cdns.gigya.com/js/gigya.js?apikey=${gigyaKey}&expiration=${GIGYA_TTL_IN_SECONDS}`,
          document
        );
      }
      if (oneTrustCookieID) {
        createOneTrustCookieScript(oneTrustCookieID, document);
        createOneTrustCookieScriptConsent(oneTrustCookieID, document);
      }
    };

    onMounted(() => {
      createScripts();
      checkTechnicalCookiesIntervalId.value = window.setInterval(
        () => checkTechnicalCookies(),
        2000
      );
    });
    checkTechnicalCookies();
    onBeforeUnmount(() => {
      window.clearInterval(checkTechnicalCookiesIntervalId.value);
    });

    const route = useRoute();
    const headerTheme = computed(
      () => (route.meta?.headerTheme as 'light' | 'dark') || 'dark'
    );

    return {
      BUTTON_THEMES,
      errors,
      firstError,
      go,
      handleModalClose,
      headerTheme,
      isLoggedIn,
      logoutAction,
    };
  },
});
