/* eslint-disable @typescript-eslint/no-explicit-any */

const FETCHING_DELAY = 200;
const ONE_HOUR_IN_S = 60 * 60;
const TWO_DAYS_IN_S = ONE_HOUR_IN_S * 24 * 2;
const TOKEN_TTL = TWO_DAYS_IN_S * 2; // JWT expiration time

type Action = (arg0: any, payload?: any) => void;

declare global {
  interface Window {
    gigya: any;
  }
}

/*
  Documentation available in:
  https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US/41353af770b21014bbc5a10ce4041860.html
*/
interface GetJWTResponse {
  id_token: string;
  time: string;
  fullEventName: string;
  callId: string;
  errorCode: number;
  errorDetails?: string;
  errorMessage?: string;
}

/*
  Documentation available in:
  https://help.sap.com/viewer/8b8d6fffe113457094a17701f63e3d6a/GIGYA/en-US/cab69a86edae49e2be93fd51b78fc35b.html
 */
interface GetAccountInfoResponse {
  uid: string;
  profile: {
    email: string;
  };
  errorCode: number;
  errorDetails?: string;
  errorMessage?: string;
}

/**
 * GigyaService integration
 * It waits until the gigya instance is present in the global window obj
 * and then it performs the JWT and accountInfo fetch using its JS
 * methods.
 * In the case that the service can't inject the gigya instance, it timeouts
 * after 10s
 * For more info check:
 * https://jira.avantgarde-digital.de/browse/PMIDCE-177
 */
class GigyaService {
  #fetchingTimeout = 10000;
  fetchingInterval: number | ReturnType<typeof setInterval>;
  fetchingTimeoutId: number | ReturnType<typeof setTimeout>;
  gigyaInstance: any;
  errorAction: any;
  logoutAction: any;

  constructor({
    jwtAction,
    userAction,
    errorAction,
    logoutAction,
  }: {
    jwtAction: Action;
    userAction: Action;
    errorAction: Action;
    logoutAction: Action;
  }) {
    this.errorAction = errorAction;
    this.logoutAction = logoutAction;
    // stop fetching after 10s if it didn't succeed
    this.fetchingInterval = window.setInterval(() => {
      if (window?.gigya) {
        this.gigyaInstance = window.gigya;
        this.fetchJWT(jwtAction);
        this.fetchAccountInfo(userAction);
        this.clearIntervalsAndTimeouts();
      }
    }, FETCHING_DELAY); // try to connect to gigya every 200ms
    this.fetchingTimeoutId = setTimeout(
      this.handleFetchTimeout.bind(this),
      this.#fetchingTimeout
    );
    return this;
  }

  clearIntervalsAndTimeouts() {
    clearInterval(this.fetchingInterval as number);
    clearInterval(this.fetchingTimeoutId as number);
  }

  handleFetchTimeout() {
    this.clearIntervalsAndTimeouts();
    this.errorAction('Fetching timeout');
  }

  fetchJWT(action: Action) {
    const callback = ({ id_token: JWTToken, errorCode }: GetJWTResponse) => {
      // Code '0' indicates success, any other number indicates failure.
      if (errorCode !== 0) {
        this.errorAction(`Error while fetching the JWT: ${errorCode}`);
      } else {
        console.log('**** GigyaService/fetchJWT', JWTToken);
        action(JWTToken); // call the given action with the token
      }
    };
    this.gigyaInstance.accounts.getJWT({ callback, expiration: TOKEN_TTL });
  }

  fetchAccountInfo(action: Action) {
    console.log('**** GigyaService/fetchAccountInfo');
    const callback = (response: GetAccountInfoResponse) => {
      // Code '0' indicates success, any other number indicates failure.
      if (response.errorCode !== 0) {
        this.errorAction(
          `Error while fetching the Account Info: ${response.errorCode}`
        );
      } else {
        action(response); // call the given action with the account info
        // Handle automatic app logout when user is logged out from within gigya
        this.gigyaInstance.accounts.addEventHandlers({ onLogout: this.logoutAction });
      }
    };
    this.gigyaInstance.accounts.getAccountInfo({ callback });
  }
  logout(): Promise<boolean> {
    return new Promise((resolve) => {
      console.log('logoutCallback')
      const logoutCallback = (response) => {
        console.log('response', response)
        const res =
          typeof response.errorCode === 'number' && response.errorCode === 0;
        console.error('**** GigyaService/logout', response, res);
        resolve(res);
      };
      this.gigyaInstance.accounts.logout({ callback: logoutCallback });
    });
  }
}

const mockWindowGigya = () => ({
  accounts: {
    getJWT: ({ callback }: { callback: any }) => {
      setTimeout(() => {
        // callback({ errorCode: 404 });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const hash = (Math.random() * 10000).toFixed(0);
        const id_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlJFUTBNVVE1TjBOQ1JUSkVNemszTTBVMVJrTkRRMFUwUTBNMVJFRkJSamhETWpkRU5VRkJRZyJ9.eyJpc3MiOiJodHRwczovL2ZpZG0uZ2lneWEuY29tL2p3dC8zX2ppVU50VlJtemtqdGZkdGw5Z0NKbTZQOEM0OGhGamQxYzZwYy1fdlRxR0ZEN2V4S01vT0pfQkF1djJqRmdQX04vIiwiYXBpS2V5IjoiM19qaVVOdFZSbXpranRmZHRsOWdDSm02UDhDNDhoRmpkMWM2cGMtX3ZUcUdGRDdleEtNb09KX0JBdXYyakZnUF9OIiwiaWF0IjoxNjc4MTE4MzM5LCJleHAiOjE2Nzg0NjM5MzksInJpc2tTY29yZSI6MC4wLCJzdWIiOiJjM2NiODg3MzA0MjI0ZjU5YTJhOTY2ZDkyMjg1ZThlNyJ9.F5laruZi-Nf_rSPQephMx93hlKji_ZN9F5-pTMdcomFrgLd5BGeAaCN78c8tYnTlKm8yXoL6RMrHtF3XmBVgoTuLA8JbO9BC1VFIhszKiVrVhlNkfpoDkStYkqFa7IjvcfO2Y9ql5ErjytySnI7EZFjceGRT3Eziu4Zc3WM-3_GtUF95akjuGnVir6lWRhIrJ3Dekx2nLqlat1zk9O6dIrXeSw-GKSQ9OjH4CLzXc8QZ7viAIvSNi1pifwkFNeH-fMohE4dwIhqtkryEAyR43rGPZvJPpIXKHzX4qQcnYqoLpekxcPnoJfmOZDRxyABvXHwsQAwqHhrBALZ9s009zA';
        callback({ id_token, errorCode: 0 });
      }, 1000);
    },
    getAccountInfo: ({ callback }: { callback: any }) => {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const errorResponse = { errorCode: 404 };

        const successResponse = {
          UID: 'fake.invented.uid',
          profile: {
            email: 'testing-email@test.com',
          },
          errorCode: 0,
        };
        callback(successResponse);
      }, 1000);
    },
    addEventHandlers: ({ onLogout }) => {
      // Trigger logout after 1h
      const ONE_HOUR_IN_MS = 1000 * 60 * 60;
      const warning = () => console.warn('[Warning]: Logging out because of 1h timeout...');
      setTimeout(warning, ONE_HOUR_IN_MS - 5000);
      setTimeout(onLogout, ONE_HOUR_IN_MS);
    },
    logout: ({ callback }) => setTimeout(() => callback({ errorCode: 0 }), 1000),
  },
});

class MockedGigyaService extends GigyaService {
  constructor({
    jwtAction,
    userAction,
    errorAction,
    logoutAction,
  }: {
    jwtAction: Action;
    userAction: Action;
    errorAction: Action;
    logoutAction: Action;
  }) {
    window.gigya = mockWindowGigya();
    super({ jwtAction, userAction, errorAction, logoutAction });
    return this;
  }
}

// Export the gigyaService in production, export the mocked one elsewhere
// @TODO: discuss how to fine tune it depending on the env:
//    dev, test, production -> will create a use/env.js file handling global envs
//    and how they get defined/used in the code.
const exportedGigyaService =
  process.env.NODE_ENV === 'production' ? GigyaService : MockedGigyaService;
export default exportedGigyaService;
